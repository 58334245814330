import React from "react"
import { Container, Button, Col } from "react-bootstrap"

export default function HeroArea() {
  return (
    <div
      id="home"
      className="hero-area d-flex justify-content-start align-items-center"
    >
      <Container>
        <Col xs={7}>
          <h4 className="text-secondary">BUSINESS CONSULTING</h4>
          <h1>Connecting Dots in the Digital World</h1>
          <p>
            Symbiot AM LLC is a sales advisory, enablement and delivery service
            focusing on digital transformation and business process. We are
            powered by an extensive global eco system of suppliers and partners
          </p>
        </Col>
        <Button variant="secondary" href="#contact">
          Get Started
        </Button>
      </Container>
    </div>
  )
}
