import React from "react"
import { Card, Container, Row } from "react-bootstrap"
import digitalTransformation from "../../images/assets/digital-transformation.png"
import businessProcess from "../../images/assets/business-process.png"
import suppliersPartners from "../../images/assets/suppliers-partners.png"

export default function ServicesSection() {
  return (
    <div className="d-flex align-items-center py-5 bg-success">
      <Container>
        <div className="d-flex justify-content-center my-3">
          <h3>Our Sectors</h3>
        </div>
        <Row className="services-cards-row pb-3">
          <Card className="service-card shadow-sm py-4">
            <Card.Body className="service-body">
              <img
                src={digitalTransformation}
                alt="Icons made by ultimatearm from www.flaticon.com"
                style={{ width: "60px" }}
              />
              <Card.Title>Digital Transformation</Card.Title>
              <Card.Text>
                Implementing new transformational technology from hardware,
                software development and services.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="service-card shadow-sm py-4">
            <Card.Body className="service-body">
              <img
                src={businessProcess}
                alt="Icons made by Freepik from www.flaticon.com"
                style={{ width: "60px" }}
              />
              <Card.Title>Circular Economy</Card.Title>
              <Card.Text>
                Operating in markets that manage Electronic Waste Recycling, ITA
                Disposition &amp; Refurbishment, Brand Protection and
                Refrigerant Gas Recycling.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="service-card shadow-sm py-4">
            <Card.Body className="service-body">
              <img
                src={suppliersPartners}
                alt="Icons made by surang www.flaticon.com"
                style={{ width: "60px" }}
              />
              <Card.Title>Talent</Card.Title>
              <Card.Text>
                With over 20 years industry experience, we offer small to large
                scale recruitment, training and reskilling oppotunities.
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </div>
  )
}
