import React from "react"
import "../styles/css/index.min.css"

import Layout from "../components/Layout/Layout"
import HeroArea from "../components/Hero/HeroArea"
import PartnerSection from "../components/Partners/PartnerSection"
import ServicesSection from "../components/Services/ServicesSection"
import AboutSection from "../components/AboutUs/AboutSection"
import ContactSection from "../components/Contact/ContactSection"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <>
      <Layout>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Symbiot-AM</title>
          <link rel="canonical" href="https://symbiot-am.com/" />
          <meta
            name="description"
            content="Symbiot AM is a sales advisory, enablement and delivery service focusing on digital transformation and business process."
          />
        </Helmet>
        <HeroArea />
        <PartnerSection />
        <ServicesSection />
        <AboutSection />
        <ContactSection />
      </Layout>
    </>
  )
}
