import React from "react"
import { Col, Container, Form, Row, Button } from "react-bootstrap"

export default function ContactSection() {
  // const [form, setForm] = useState(false)
  // const handleSubmit = e => {
  //   e.preventDefault()
  //   setForm(!form)

  //   setTimeout(() => navigate("/"), 5000)
  // }

  return (
    <Container id="contact" fluid className="bg-success">
      <Row>
        <Col
          md={6}
          className="d-flex flex-column align-items-center justify-content-center contact-mobile"
        >
          <h2 className="mb-4">Contact Us</h2>
          <div className="w-50 contact-p">
            <p>
              We have built successful companies in the UK, USA, Australia and
              the Middle East so you know you're in good hands. Get in touch
              today and see how we can help transform your business.
            </p>
          </div>
          <div className="text-left w-50 pt-4">
            <h4>United Kingdom</h4>
            <h4>Australia</h4>
            <h4>United Arab Emirates</h4>
            <h4>United States of America</h4>
          </div>
        </Col>
        <Col className="contact-bg" md={6}>
          <Form
            className="bg-light p-3 my-5 mx-4 rounded text-dark shadow-lg contact-form"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="Contact"
          >
            <Form.Group className="my-1" controlId="formFullName">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter name..."
                required
                name="name"
              />
            </Form.Group>
            <Form.Group className="my-1" controlId="formEmail">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                required
                name="email"
              />
            </Form.Group>
            <Form.Group className="my-1" controlId="formCompany">
              <Form.Label>Company:</Form.Label>
              <Form.Control
                placeholder="enter company name..."
                name="company"
              />
            </Form.Group>
            <Form.Group className="my-1" controlId="formPhone">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control placeholder="enter phone number..." name="phone" />
            </Form.Group>
            <Form.Group className="my-1" controlId="formMessage">
              <Form.Label>Message:</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="write your message here..."
                required
                name="message"
                className="contact-textarea"
              />
            </Form.Group>
            <p className="m-2" style={{ fontSize: "0.8rem" }}>
              By submitting this form, you agree to the processing of your
              personal data by Symbiot as described in the{" "}
              <a href="/privacy">Privacy Policy.</a>
            </p>
            <input type="hidden" name="form-name" value="Contact" />
            <Button variant="secondary" className="mt-2" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}
