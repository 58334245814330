import React from "react"
import { Col } from "react-bootstrap"

export default function AboutSection() {
  return (
    <div id="about" className="about-section">
      <div className="side-image"></div>
      <Col className="d-flex align-items-center">
        <div className="px-5">
          <h4 className="text-secondary">ABOUT US</h4>
          <h2>Who We Are</h2>
          <p>
            We are a collective of thought leaders with over 30 years’
            experience in each sector covering every corner of the globe. We are
            not just an advisory and strategy builder, as we don’t stop there.
            Our aim is to deliver! Our strength is sales and marketing
            execution, focusing on digital transformation, partnerships and
            driving revenue. We focus on connecting the dots in the digital
            world to deliver accelerated revenue streams and effective solutions
            that have a track record of success enabling the capitalization of
            national and international markets. Aligning technologies across
            hardware, software, services, people, and training to accelerate
            growth in both current and new business streams.
          </p>
        </div>
      </Col>
    </div>
  )
}
