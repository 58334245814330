import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import airnowLogo from "../../images/assets/airnow-logo.svg"
import codenationLogo from "../../images/assets/codenation-logo.png"
import yondercloudLogo from "../../images/assets/yondercloud-logo.png"
import visiontrackLogo from "../../images/assets/visiontrack-logo.svg"
import corsightLogo from "../../images/assets/corsight-logo.svg"
import caseoneLogo from "../../images/assets/caseone-logo.svg"
import iodmLogo from "../../images/assets/IODM-logo.svg"
import kasperLogo from "../../images/assets/kasper-logo.png"

export default function PartnerSection() {
  return (
    <div id="tech" className="partner-section">
      <Container>
        <div className="d-flex justify-content-center my-3">
          <h3>Who We Partner With</h3>
        </div>
        <Row
          direction="horizontal"
          gap={4}
          className="d-flex justify-content-center"
        >
          <Col sm xs={6} className="logo-container my-3">
            <a href="https://airnow.com/" target="_blank" rel="noreferrer">
              <img src={airnowLogo} alt="airnow logo" />
            </a>
          </Col>
          <Col sm xs={6} className="logo-container my-3">
            <a
              href="https://wearecodenation.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={codenationLogo} alt="code nation logo" />
            </a>
          </Col>
          <Col sm xs={6} className="logo-container my-3">
            <a
              href="https://yondercloud.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={yondercloudLogo} alt="yondercloud logo" />
            </a>
          </Col>
          <Col sm xs={6} className="logo-container my-3">
            <a
              href="https://www.visiontrack.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={visiontrackLogo} alt="visiontrack logo" />
            </a>
          </Col>
        </Row>
        <Row
          direction="horizontal"
          gap={4}
          className="d-flex justify-content-center"
        >
          <Col sm xs={6} className="logo-container my-3">
            <a href="https://www.corsight.ai/" target="_blank" rel="noreferrer">
              <img src={corsightLogo} alt="corsight logo" />
            </a>
          </Col>
          <Col sm xs={6} className="logo-container my-3">
            <a href="https://case.one/" target="_blank" rel="noreferrer">
              <img src={caseoneLogo} alt="case one logo" />
            </a>
          </Col>
          <Col sm xs={6} className="logo-container my-3">
            <a href="https://iodm.com.au/" target="_blank" rel="noreferrer">
              <img src={iodmLogo} alt="iodm logo" />
            </a>
          </Col>
          <Col sm xs={6} className="logo-container my-3">
            <a
              href="http://www.kasperworld.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={kasperLogo} alt="kasper logo" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
